/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.lavanguardia.com/politica/20120605/54304029563/rajoy-europa-integracion-fiscal-union-bancaria-eurobonos.html"
  }, React.createElement(_components.img, {
    src: "images/ecologiablog_balosa_ue_rota.jpg",
    alt: "Una baldosa de la Union Europea rota en pedazos",
    title: "Baldosa UE Rota"
  })), "Hoy la Unión Europea da un paso atrás, por lo visto la crisis está implantando en el pensamiento europeo las ideas más xenófobas, no tiene ningún sentido ", React.createElement(_components.a, {
    href: "http://www.lavanguardia.com/politica/20120605/54304029563/rajoy-europa-integracion-fiscal-union-bancaria-eurobonos.html"
  }, "buscar más Europa"), " para salir de la crisis al mismo tiempo que se hace menos Europa cerrando fronteras, a día de hoy la Unión Europea está menos unida que antes y no más, por que una cosa son las palabras y otra ", React.createElement(_components.a, {
    href: "http://www.abc.es/20120429/comunidad-catalunya/abcp-cierre-fronteras-temor-vandalismo-20120429.html"
  }, "los hechos"), "."), "\n", React.createElement(_components.p, null, "Pero no quería destacar esto si no otra cosa más sutil, por una parte se afirma que los controles se restablecerá cuando exista ", React.createElement(_components.em, null, "“una amenaza grave para la seguridad pública"), "”, una excusa muy típica a la que ya nos estamos acostumbrando en ésta tan desarrollada ", React.createElement(_components.a, {
    href: "http://www.versvs.net/la-sociedad-de-control/",
    title: "La sociedad de control - Jose F. Alcántara"
  }, "sociedad de control"), ". Por otra parte se dice que se utilizará para evitar la afluencia de inmigrantes."), "\n", React.createElement(_components.p, null, "Curioso el asunto puesto que se relacionan dos conceptos que no tienen en apariencia nada en común a no ser que añadamos a la mezcla un importante ingrediente, xenofobia. Por que sin el miedo a los extranjeros no se concibe cómo se ha llegado a la conclusión de que la inmigración implica una grave amenaza para la seguridad pública."), "\n", React.createElement(_components.p, null, "La Unión Europea cada vez me parece más como una utopía, como algo que podría haber sido pero para lo que la vieja Europa no está preparada."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
